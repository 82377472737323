const multiOptionsToObject = (options) => {

    if(options === undefined || options.length === 0){ 
      return {};
    }

    return options.reduce((acc, elem) => {
      acc[elem] = true;
      return acc;
    }, {});

}

export default multiOptionsToObject;