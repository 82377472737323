import React, { useMemo } from 'react';
import styled from 'styled-components';
import SbEditable from 'components/SbEditableExtended';

import WhiteBox from 'components/blocks/blockPartials/whiteBox';

import theme, {bp} from 'settings/theme';

import calculateBottomSpacingClass from 'components/blocks/utils/calculateBottomSpacingClass';
import multiOptionsToObject from 'components/blocks/utils/multiOptionsToObject';



// Component
const Component = (props) => {

  

  const displaySettings = useMemo(() => multiOptionsToObject(props.block?.display_settings), [props.block?.display_settings]);
  const spacingBottomClass = useMemo(() => calculateBottomSpacingClass(props.block?.bottom_spacing), [props.block?.bottom_spacing]);

  if(!props.block){
    return(<div>Storyblok block missing</div>);
  }

	return(
    <SbEditable content={props.block}>
      <StyledComponent {...props} className={`blockAutoplayVideo ${spacingBottomClass}`}>
        <WhiteBox 
            removeWhiteBox={displaySettings.removeWhiteBox} 
        >

          <div className="blockAutoplayVideo-wrapper">

            <div className="blockAutoplayVideo-inner">

              <div className="blockAutoplayVideo-player">
                <iframe 
                  src={props.block.url}
                  width="100%" 
                  height="100%" 
                  frameBorder="0" 
                  allowFullScreen
                ></iframe>
              </div>

            </div>

            </div>

          {props.block.caption &&
            <div className="blockAutoplayVideo-caption">
              {props.block.caption}
            </div>
          }

        </WhiteBox>
      </StyledComponent>
    </SbEditable>
	);

}

export default Component;


// Styles
const StyledComponent = styled.div`

  &:last-child{
    margin-bottom: 0;
  }


  .blockAutoplayVideo-wrapper{
    overflow: hidden;
    position: relative;
    padding-top: 45%;
  }

  .blockAutoplayVideo-inner{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
  }
  
  .blockAutoplayVideo-player{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateY(-10%);
  }

  .blockAutoplayVideo-caption{
    padding: 1rem 10vw;
    font-family: ${theme.typography.fontFamilySecondary};
    font-weight: bold;
    text-align: center;
  }


  @media (min-width: ${bp(3)}) {

    .blockAutoplayVideo-wrapper{
      padding-top: 40%;
    }

    .blockAutoplayVideo-player{
      transform: translateY(-15%);
    }

  }

  @media (min-width: ${bp(4)}) {

    .blockAutoplayVideo-wrapper{
      padding-top: 30%;
    }

    .blockAutoplayVideo-player{
      transform: translateY(-22%);
    }

  }

`