import React from 'react';
import styled, {css} from 'styled-components';

import theme, {bp, color} from 'settings/theme';


type Props = {
  removeWhiteBox?: boolean
};

type StyledProps = Props

// Component
const Component = (props) => {

  return(
    <StyledComponent removeWhiteBox={props.removeWhiteBox} className={`whiteBox ${props.className ? props.className : ''}`}>
      {props.children}
    </StyledComponent>
  );

}

Component.defaultProps = {
  removeWhiteBox: false
} as Partial<Props>;

export default Component;


// Styles
const StyledComponent = styled.div<StyledProps>`
    
  margin-top: -1px;

  /* Remove White Box */
  ${props => props.removeWhiteBox !== true &&
    css`
      border-top: solid 1px ${color.lines};
      border-bottom: solid 1px ${color.lines};
      background-color: ${color.white};
    `
  }

`