const calculateBottomSpacingClass = (val) => {

  switch (val) {
    case 'none':
      return '';
    case 'half':
      return 'spacingBottomHalf';
    case 'double':
      return 'spacingBottomDouble';
    default:
      return 'spacingBottom';
  };

};

export default calculateBottomSpacingClass;