import SbEditable from 'storyblok-react'

interface ExtendedSbEditableProps extends React.ComponentProps<typeof SbEditable> {
  children?: React.ReactNode;
}

const ExtendedSbEditable: React.FC<ExtendedSbEditableProps> = (props) => {
  return <SbEditable {...props}>{props.children}</SbEditable>;
};

export default ExtendedSbEditable;